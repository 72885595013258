import { Link } from "react-router-dom";
import { Box, Flex, Text, IconButton, useDisclosure, VStack } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import Logo from "../assets/Logo";

interface MenuItemProps {
  children: React.ReactNode;
  isLast?: boolean;
  to?: string;
}

const MenuItem = ({ children, isLast, to = "/" }: MenuItemProps) => {
  return (
    <Text mb={{ base: isLast ? 0 : 8, sm: 0 }} mr={{ base: 0, sm: isLast ? 0 : 8 }} display="block">
      <Link to={to}>{children}</Link>
    </Text>
  );
};

const Header = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={4}
      p={8}
      bg={["primary.500", "primary.500", "transparent", "transparent"]}
    >
      <Flex align="center">
        <Logo height="50" width="161.5" />
      </Flex>

      <Box display={{ base: "block", md: "none" }} onClick={onToggle}>
        <IconButton
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          variant="outline"
          aria-label="Toggle Navigation"
        />
      </Box>

      <Box
        flexBasis={{ base: "100%", md: "auto" }}
        display={{ base: isOpen ? "block" : "none", md: "flex" }}
      >
        <VStack
          align="center"
          justify={["center", "space-between", "flex-end", "flex-end"]}
          direction={["column", "row", "row", "row"]}
          pt={[4, 4, 0, 0]}
          spacing={4}
          width="100%"
        >
          {/* <MenuItem to="/">Home</MenuItem>
          <MenuItem to="/how">How It works</MenuItem>
          <MenuItem to="/features">Features</MenuItem>
          <MenuItem to="/pricing">Pricing</MenuItem>
          <MenuItem to="/signup">
            <Button
              size="sm"
              rounded="md"
              _hover={{
                bg: ["primary.100", "primary.100", "primary.600", "primary.600"],
              }}
            >
              Create Account
            </Button>
          </MenuItem> */}
          <MenuItem><ColorModeSwitcher /></MenuItem>
        </VStack>
      </Box>
    </Flex>
  );
};

export default Header;
