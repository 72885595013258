import { Flex } from "@chakra-ui/react"
import Header from "../components/home/Header"
import Hero from "../components/home/Hero"
import home1 from "../components/assets/home1.jpeg"

export default function Landing() {
  return (
    <Flex
      direction="column"
      align="center"
      maxW={{ xl: "1200px" }}
      m="0 auto"
    >
      <Header />
      <Hero
        title="Your personal AI medical assistant"
        subtitle="Interact with your medical data. Get instant answers. Get offline insights"
        image={home1}
      />
      {/* <Footer /> */}
    </Flex>
  )
}
