import { FunctionComponent } from "react";
import SignIn from './SignIn';
import {
  Box,
  Flex,
  Image,
  Heading,
  Stack,
  Highlight,
} from "@chakra-ui/react";

interface HeroProps {
  title: string;
  subtitle: string;
  image: string;
}

const Hero: FunctionComponent<HeroProps> = ({
  title,
  subtitle,
  image,
}) => {
  return (
    <Flex
      align="center"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      direction={{ base: "column-reverse", md: "row" }}
      wrap="nowrap"
      minH="70vh"
      px={8}
      mb={16}
    >
      <Stack
        spacing={4}
        w={{ base: "80%", md: "40%" }}
        align={["center", "center", "flex-start", "flex-start"]}
      >
        <Heading
          as="h1"
          size="xl"
          fontWeight="bold"
          color="primary.800"
          textAlign={["center", "center", "left", "left"]}
        >
          <Highlight
            query='AI'
            styles={{ px: '2', py: '1', bg: 'teal.100' }}
          >
            {title}
          </Highlight>
        </Heading>
        <Heading
          as="h2"
          size="sm"
          color="primary.800"
          opacity="0.8"
          fontWeight="normal"
          lineHeight={1.5}
          textAlign={["center", "center", "left", "left"]}
        >
          {subtitle}
        </Heading>
        <SignIn />
      </Stack>
      <Box w={{ base: "50%", sm: "60%", md: "50%" }} mb={{ base: 12, md: 0 }}>
        <Image src={image} sizes="100%" rounded="1rem" shadow="2xl" />
      </Box>
    </Flex>
  );
};

export default Hero;
